<template>
  <main class="main page supporting support CMS_pages">
    <div class="heroBanner heroBannerBGcolor" id="faq">
      <h1 class="section__heading">Frequently Asked Questions</h1>
    </div>
    <section class="section">
      <!-- <h1 class="section__heading">FAQ</h1> -->
      <div class="text freRight">
        <h2>About ToTree</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Is toTree an interior design company?</template>
          <template v-slot:content>
            <p>Nope. We’re a furniture-shopping service (the first-ever). But we do provide the expert guidance and service of an interior designer – sans the cost - with a fast and easy online process. In short, we save folks time and money by finding the right furniture for them.</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> How does the process work? </template>
          <template v-slot:content>
            <p>Simply take our Style Survey in which you’ll a) answer a few questions about your needs, tastes, and budget and  b) submit a floor plan & photos of your room (if needed). Within 72 hours, we’ll send you personalized furniture options. Select what you like and order with one click!</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> I’m not sure what I need for my place. Is this a problem?</template>
          <template v-slot:content>
            <p>Not at all. Our Living Space Specialists will be happy to help you figure that out.</p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> I don’t know anything about furniture. Can I still use toTree?</template>
          <template v-slot:content>
            <p>Without question! That’s precisely why we founded toTree: to make shopping for great furniture so simple that absolutely anyone can use it. </p>
          </template>
        </accordion>
      </div>
        <h2>Pricing</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> How much does toTree charge for their service?</template>
          <template v-slot:content>
            <p>Our service fee is $149 and valid for one year. Our fee includes furnishing up to four rooms, so if you buy living room furniture today and want to wait a few months to get your bedroom furniture, you won’t have to pay another service fee!</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Can I apply my $149 service fee to the cost of my furniture?</template>
          <template v-slot:content>
            <p>YES! Our $149 fee is applied to any purchase of furniture. (One time use only)</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Is my dedicated specialist a “real” person? </template>
          <template v-slot:content>
            <p>Sure is. And it’s a person with extensive home furnishings expertise who’s available throughout the process to help. Like having your own furniture concierge!</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What additional benefits do I get for my $149 service fee?</template>
          <template v-slot:content>
            <p>Additional benefits include:</p>
            <p>1. Your own dedicated specialist throughout the process to answer any/all questions</p>
            <p>2. We handle returns/exchanges</p>
            <p>3. Flat rate shipping</p>
            <p>4. Our Insta-Order feature: select this option and all your furniture selections will be in-stock and ready to ship.</p>
            <p>5. A layout suggestion for your new furniture</p>
            <p>6. Service fee is valid for one year - and up to four rooms!</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Can I to add to my order after I’ve made my purchases?</template>
          <template v-slot:content>
            <p>Sure! You can add to any toTree order – without a fee – for up to six (6) months.</p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Does the fee include multiple residences?</template>
          <template v-slot:content>
            <p>Sorry. The fee is per residence.</p>
          </template>
        </accordion>
        </div>
        <h2>Furniture</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What furniture brands do you offer?</template>
          <template v-slot:content>
            <p>We offer 10 trusted, quality brands: West Elm, Room & Board, Article, Burrow, EQ3, Blu Dot, Sabai, Thuma. Surya, and BenchMade Modern.</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> How do you select the brands you offer?</template>
          <template v-slot:content>
            <p>
              Research. Lots of it. We spent countless hours searching for brands that align with toTree’s core tenets: quality, value, and sustainability.</p>
            <p>But that’s not all. To add an extra layer of trust, we visited the showrooms and/or headquarters of each brand to personally see, feel, and test out each brands’ furniture. Check out our Testing Video
              <a href="/video/test.mp4" data-fancybox>here</a>.
            </p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What sort of furniture do you offer?</template>
          <template v-slot:content>
            <p>To keep the process streamlined, we offer “foundational” furniture for living rooms, bedrooms, and dining rooms in three timeless styles: Mid-Century Modern, California Coastal and Industrial.</p>
            <p>1. Living room: sofas, chairs, sideboards, coffee tables, end tables, benches, ottomans, rugs</p>
            <p>2. Bedroom: beds, dressers, nightstands, rugs, benches, chairs</p>
            <p>3. Dining room: tables, chairs, stools, rugs, sideboards</p>
            <!-- <p>OFFICE: desks, chairs</p> --> <!-- KEEP: OFFICE -->
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span>What should I expect to pay for furniture?</template>
          <template v-slot:content>
            <p>Quality furniture - well-made, long-lasting, and better for the environment – is somewhat pricier than the cheap, popular “disposable” furniture that cannot be recycled. Prices also depend on a variety of factors: size, materials, construction, etc. But we’re happy to try and work with anyone’s budget!</p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Does toTree markup their furniture prices?</template>
          <template v-slot:content>
            <p>Never! Customers pay the same price for furniture as they would if they bought directly from the brands.</p>
          </template>
        </accordion>
        </div>
        <!-- <accordion>
          <template v-slot:title>What if I don't want to pay for everything at once?</template>
          <template v-slot:content>
            <p>We offer TK.</p>
          </template>
        </accordion> -->

        <h2 id="budget">Budget</h2>
        <accordion ref="budget">
          <template v-slot:title><span class="icon-double-arrow"></span> How much should I expect to spend on furniture?</template>
          <template v-slot:content>
            <p>The eternal question! So, furniture ranges in price based on a host of factors including materials, size, and manufacturing process. We don’t offer any types of cheap “disposable” furniture that lasts six months and winds up in landfills. Our commitment to quality means our offerings do cost a bit more, but it’s worth a little extra knowing your furniture is well made, will last longer, and is far better for our planet. To give you a better sense of the costs, we think a little story will help:</p>

            <h2>A Furniture Tale</h2>

            <p>“What’s your budget?” asked my girlfriend as we entered the Chelsea showroom. Exasperated by the state of my apartment, she’d kindly taken me shopping for living room and bedroom furniture. But I couldn’t answer her question. Budget? I hadn’t known <em>what</em> furniture to buy or <em>where</em> to buy it. How could I possibly know <em>how much I should pay for it??</em></p>

            <p>I learned quickly. First and foremost? It isn’t cheap. Quality furniture – the stuff that doesn’t fall apart after a year or is made from non-recyclable crap – cost more than I’d imagined. Not Madison Avenue prices ($20k sofa anyone?), but a sizable investment. Like any big purchase (top of the line computer, exquisite suit, high-performance car), you get what you pay for. Furniture is no different. Craftsmanship, materials, size, longevity are all factors in pricing. It’s why one leather sofa might cost $2k while another, that looks the same, costs $4k. </p>

            <p>Prior to that day, I figured I could get everything for a few thousand bucks. But when the dust settled, my living room (sofa, sideboard, coffee table, reading chair, and rug) totaled $7300 and my bedroom (bed, dresser, rug) was nearly $4k. A shock? Yep. But every time I sat on that sofa or lay down in bed, I was thrilled. Guests I invited over were genuinely impressed. Eight years later, I still have that furniture. Still love it. And I never once regretted the price tag. </p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Are there ways to keep the overall price down?</template>
          <template v-slot:content>
            <p>Absolutely. While bigger pieces like sofas, dressers and sideboards tend to have less wiggle room, we offer a selection of very reasonable coffee tables, end tables, nightstands, and rugs (that are still great quality). Just make sure to mention it to your specialist.</p>
          </template>
        </accordion>
      </div>
        <h2>Process</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Do I have to buy everything presented on my room style boards?</template>
          <template v-slot:content>
            <p>Not at all. You can select as many pieces as you like or nothing at all.</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What if I’m not crazy about the furniture on my two style boards?</template>
          <template v-slot:content>
            <p>If we’ve missed the mark on any or all the items on your style board, we’ll gladly provide you with alternatives. If we somehow get that wrong, we’ll set up a call to figure out what’s wrong – and more importantly make it right!</p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Will you work with the furniture I already own?</template>
          <template v-slot:content>
            <p>We certainly can. We’ll just need a photo of the piece(s) we’ll be working with.</p>
          </template>
        </accordion>
        </div>
        <h2 id="measuring">Measuring</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> How important are accurate measurements? </template>
          <template v-slot:content>
            <p>On a scale of 1-10, accurate measurements are a 10! To avoid the hassle and potential return fees of an item that doesn’t fit into a doorway, we urge customers to make certain they’ve got spot-on measurements.</p>
          </template>
        </accordion>

        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> In addition to room(s) what else might need to be measured?</template>
          <template v-slot:content>
            <p>
              That depends on your living space and “furniture path” - the route furniture must travel throughout a home/apartment. Pre-war apartment buildings, older homes, and brownstones can have especially tricky doorways, hallways and stairwells. At minimum, we strongly recommend measuring <strong>doorways</strong> (don’t forget <a href="http://www.mcfarlanddoor.com/jambs.aspx" target="_blank">the door jamb</a>), <strong>hallways, stairwells and elevators</strong>.
            </p>
            <p>Here’s a link from one of our brands that shows the <a href="https://www.roomandboard.com/ideas_advice/buying_guides/measuring_for_furniture_delivery/modal.ftl" target="_blank">proper measuring techniques</a>.</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What if I don’t have a tape measure at home?</template>
          <template v-slot:content>
            <p>There is a measuring app on most phones. The two main options are: </p>
            <p>iPhone: <a target="_blank" href="https://support.apple.com/en-us/HT208924">Measure</a><br>
              Cost: free</p>
            <p>Android: <a target="_blank" href="https://play.google.com/store/apps/details?id=fr.ecp.ruler.app.paid">Ruler+ (by Xalpha Lab)</a><br>
              Cost: $1.49</p>
          </template>
        </accordion>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What if I’m not confident in my measuring skills? </template>
          <template v-slot:content>
            <p>We understand. If that’s the case, we can a) jump on a call/FaceTime and walk you through the process or b) if you’re in the NYC area we’ll send a toTree team member to your home to measure for you. Free of charge! </p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> My new apartment has a floorplan – can I use that instead of measuring myself? </template>
          <template v-slot:content>
            <p>As long as the floorplan has accurate measurements, go for it! We’ll just need you to input the dimensions in the style survey and then download the floorplan (to be safe).</p>
          </template>
        </accordion>
        </div>
        <h2 id="shipping">Shipping/delivery</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> How does the shipping process work?</template>
          <template v-slot:content>
            <p>Currently, ‘drop shipping’ through our individual brands is the lone option. We’ll provide you with all your necessary tracking/shipping information and phone numbers as well as reminders about any upcoming deliveries.</p>
          </template>
        </accordion>

        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Is White Glove Service available?</template>
          <template v-slot:content>
            <p>Sure is. Upon checkout, you’ll see that option where available. Just click the button and you’re all set.</p>
          </template>
        </accordion>

        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What if there is an issue with my delivery?</template>
          <template v-slot:content>
            <p>Contacting individual brands directly are the best and fastest solution. In the event of a serious issue, we’re here to help. </p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What can I expect to pay for shipping?</template>
          <template v-slot:content>
            <p>It all depends on how many items you’re buying, what sort of furniture (larger items naturally cost more to ship) and your location. Unfortunately, shipping prices have risen over the past few years (especially during the pandemic). To try and reign in those costs, toTree has established drop shipping ‘caps,’ a maximum amount (per order) you’ll ever have shell out to get your furniture. They are as follows:</p>
            <p>Under $3k: $199</p>
            <p>$3k - $5k: $349</p>
            <p>$5k - $7k: $459</p>
            <p>$7k - $$10k: $549</p>
            <p>Over $10k: $649</p>
            <p>And just a friendly reminder, the ‘cap’ only applies to drop shipping – not white glove service.</p>
          </template>
        </accordion>
        </div>
        <!-- <accordion>
          <template v-slot:title>How does the One, toTree Service work?</template>
          <template v-slot:content>
            <p>Your individual pieces of furniture will be shipped to our warehouse. Once everything has arrived, we’ll reach out and arrange a convenient delivery time. </p>
            <p>Not only will Our toTree logistics team will deliver, assemble, and inspect each item, but they’ll remove the furniture that’s being replaced (conditions apply) and take it back to our warehouse to have it recycled or donated to charity. </p>
          </template>
        </accordion> -->
        <!-- <accordion>
          <template v-slot:title>Will the One, toTree Service be available in other cities?</template>
          <template v-slot:content>
            <p>It sure will!  We plan to expand the service to 70 US cities. </p>
          </template>
        </accordion> -->

        <h2>Returns</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What’s the return policy on purchased furniture? </template>
          <template v-slot:content>
            <p>You can find the return policy for each of our brands here:</p>
            <p><a target="_blank" href="https://www.article.com/help-center/returns">Article</a> </p>
            <p><a target="_blank" href="https://benchmademodern.com/pages/return-exchange-policy">BenchMade Modern</a> </p>
            <p><a target="_blank" href="https://www.bludot.com/returns">Bludot</a> </p>
            <p><a target="_blank" href="https://burrow.com/returns">Burrow</a> </p>
            <p><a target="_blank" href="https://www.eq3.com/us/en/questions/returns-exchanges">EQ3</a> </p>
            <!-- <p><a target="_blank" href="https://support.fully.com/hc/en-us/articles/1500009651661-What-s-your-return-policy-">Fully</a> </p> -->
            <p><a target="_blank" href="https://www.roomandboard.com/customer-service/returns-exchanges">Room &amp; Board</a> </p>
            <p><a target="_blank" href="https://sabai.design/pages/faqs">Sabai</a> </p>
            <p><a target="_blank" href="https://www.surya.com/policies/#returns">Surya</a> </p>
            <p><a target="_blank" href="https://www.thuma.co/pages/faq#faq-section-ordering-returns-and-support">Thuma</a> </p>
            <!-- <p><a target="_blank" href="https://www.upliftdesk.com/returns/">Uplift Desk</a> </p> -->
            <p><a target="_blank" href="https://www.westelm.com/customer-service/return/?cm_re=GlobalLinks-_-Footer-_-Returns">West Elm</a></p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What if I need to make a return?</template>
          <template v-slot:content>
            <p>We will handle that for you. Simply contact your Living Space Specialist, explain the situation, and we’ll get the process started. Again, there are often return fees so we urge you to read the brands’ return policies before purchasing.</p>
          </template>
        </accordion>
        </div>
        <h2>Customer Support</h2>
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> What if I have a question or concern?</template>
          <template v-slot:content>
            <p>Reach out! We’re here to help any time throughout the process – and beyond.</p>
          </template>
        </accordion>
        <div class="lastac">
        <accordion>
          <template v-slot:title><span class="icon-double-arrow"></span> Will I have the same LSS (Living Space Specialist) throughout the process?</template>
          <template v-slot:content>
            <p>Yep. Because there’s nothing more annoying than having to explain your needs/issues/questions to someone new.</p>
          </template>
        </accordion>
        </div>
      </div>
    </section>

    <div style="display: none"><!-- hidden to public, for SEO only -->
      <p>toTree is a first-of-its-kind furniture shopping service. We make buying high quality, affordable home furnishings fast, easy and painless. The secret? Our Custom Ease model combines just the right amount of interior design expertise with a completely online process. Whether it’s living room, bedroom, dining room or office furniture, we have selections such as sofas, couches, sectionals, coffee tables, leather and upholstered reading chairs, sideboards, rugs, beds, desks, dressers, end tables, nightstands and dining room tables and chairs. Our timeless styles include Mid-Century Modern, Industrial, and California Coastal. And we make it user friendly. Never heard of a chaise or a modular sofa or a platform bed? Don’t know the difference between a sideboard and a credenza? Not sure if that dresser will fit? We have a glossary and measuring tools to help. Concerned about the environment? toTree is committed to sustainable furniture – without sacrificing comfort, quality, and style. The brands we offer align with our core tenets: value, comfort, and a greener planet. eco-friendly and aim to reduce furniture landfill waste. Whether you live in a studio apartment, spacious loft or house, a city or a suburb; whether you like minimalism or prefer spacious, cozy couches, we can help you buy the perfect and environmentally friendly home furnishings. Furniture shopping doesn’t have to be time consuming or exasperating. With toTree, buying sustainable furniture - quality sofas, chairs, beds, dressers, rugs, coffee tables and sideboards – has never been easier. And both men and women can reap the benefits of a comfortable, cozy, well-furnished home – whether you like Mid-Century Modern, Industrial or California Coastal. So when you think of furniture, think of toTree. </p>
    </div>

  </main>
</template>

<script>
import Accordion from '@/components/Accordion'
import { Fancybox } from '@fancyapps/ui'
import { mapState } from 'vuex'

export default {
  name: 'Support',
  components: {
    Accordion
  },
  computed: {
    ...mapState(['selectionServiceFee'])
  },
  mounted () {
    // On load, expand target accordion if applicable
    if (location.hash) {
      const targetRef = this.$refs[location.hash.slice(1)]
      if (targetRef) {
        targetRef.toggleAccordion()
      }
    }

    Fancybox.bind('[data-fancybox]', {
      closeButton: 'outside',
      template: {
        closeButton: 'close'
      }
    })
  }
}
</script>
